/** ***************************************************************************
 * Utility classes
 *************************************************************************** */

.hns-m-loading *,
.hns-m-loading ::before,
.hns-m-loading ::after {
  animation-play-state: paused !important;
}



/**
 * Extended link
 */

.hns-u-extended::before {
  content: "";
  display: block;
  inset: 0;
  position: absolute;
}

@supports not (inset: 0) {
  .hns-u-extended::before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}



/**
 * Button
 * 1: contain background and set up a stacking context
 */

:root {
  --hns-u-button--Color: currentcolor;
  --hns-u-button--FontFamily: var(--hns-global--FontFamily-special);
  --hns-u-button--FontSize: inherit;
  --hns-u-button--FontWeight: var(--hns-global--FontWeight-heavy);
  --hns-u-button--PaddingBlock: .61em;
  --hns-u-button--PaddingInline: 1.55em;
  --hns-u-button--TransitionDuration: var(--hns-global--Duration--sm);

  --hns-u-button__background--BackgroundColor: transparent;
  --hns-u-button__background--BorderColor: currentcolor;
  --hns-u-button__background--Transform: initial;

  --hns-u-button__background--hover--Transform: scale(1.05); /*skewX(15deg);*/
}

.hns-u-button {
  border: 0;
  background-color: transparent;
  color: var(--hns-u-button--Color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--hns-u-button--FontFamily);
  font-size: var(--hns-u-button--FontSize);
  font-weight: var(--hns-u-button--FontWeight);
  margin: 0;
  padding-block: var(--hns-u-button--PaddingBlock);
  padding-inline: var(--hns-u-button--PaddingInline);
  padding: .61em 1.55em;
  position: relative;  /* 1 */
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

button.hns-u-button {
  appearance: none;
}

@supports not (padding-block: var(--hns-u-button--PaddingBlock)) {
  .hns-u-button {
    padding: var(--hns-u-button--PaddingBlock) var(--hns-u-button--PaddingInline);
  }
}


/** Button background */

.hns-u-button::before {
  background-color: var(--hns-u-button__background--BackgroundColor);
  border-color: var(--hns-u-button__background--BorderColor);
  border-radius: 3px;
  border-style: solid;
  border-width: calc(1em / 14);
  content: "";
  display: block;
  inset: 0;
  position: absolute;
  transform: var(--hns-u-button__background--Transform);
  transition: transform var(--hns-u-button--TransitionDuration) ease-in-out;
  z-index: 0;
}

.hns-u-button:hover::before {
  transform: var(--hns-u-button__background--hover--Transform);
}

@supports not (inset: 0) {
  .hns-u-button::before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}


/** Button content */

.hns-u-button > * {
  position: relative;
  z-index: 1;
}
