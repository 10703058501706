/** ***********************************************************************************
 * Miscellaneous design tokens
 *********************************************************************************** */

@keyframes hns-global--keyframes-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hns-global--keyframes-hide {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}


:root {
  /**
   * Durations
   */

  --hns-global--Duration--tn: 50ms;
  --hns-global--Duration--xs: 100ms;
  --hns-global--Duration--sm: 300ms;
  --hns-global--Duration--md: 700ms;
  --hns-global--Duration--hg: 10s;


  /**
   * Timing functions
   */

  --hns-global--AnimationTimingFunction--bounce: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --hns-global--AnimationTimingFunction--easeoutcubic: cubic-bezier(0.33, 1, 0.68, 1);


  /**
   * Cursors
   */

  --hns-global--Cursor--close: url("data:image/svg+xml,%3Csvg%20width%3D%2240px%22%20height%3D%2240px%22%20viewBox%3D%220%200%2040%2040%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20fill%3D%22fff%22%20cx%3D%2220%22%20cy%3D%2220%22%20r%3D%2220%22%20style%3D%22fill%3A%23fff%3B%22%2F%3E%3Cpath%20fill%3D%22%234c5166%22%20d%3D%22M13.206%2C12.374l14.142%2C14.142l-1.515%2C1.515l-14.143%2C-14.141l1.516%2C-1.516Zm4.178%2C8.723l1.489%2C1.489l-5.556%2C5.555l-1.488%2C-1.488l5.555%2C-5.556Zm8.586%2C-8.587l1.49%2C1.49l-5.557%2C5.555l-1.488%2C-1.488l5.555%2C-5.557Z%22%2F%3E%3C%2Fsvg%3E") 20 20;
}
