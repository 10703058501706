/** ***********************************************************************************
 * Typography design tokens
 *********************************************************************************** */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&family=Oswald:wght@400;500;700&display=block');

:root {
  /**
   * Font families
   */

  --hns-global--FontFamily-normal: Nunito, sans-serif;
  --hns-global--FontFamily-special: Oswald, sans-serif;


  /**
   * Font weights
   * 1: unused
   */

  --hns-global--FontWeight-thin:       100;  /* 1 */
  --hns-global--FontWeight-extralight: 200;  /* 1 */
  --hns-global--FontWeight-light:      300;  /* 1 */
  --hns-global--FontWeight-normal:     400;
  --hns-global--FontWeight-medium:     500;
  --hns-global--FontWeight-semibold:   600;  /* 1 */
  --hns-global--FontWeight-bold:       700;
  --hns-global--FontWeight-extrabold:  800;  /* 1 */
  --hns-global--FontWeight-black:      900;
  --hns-global--FontWeight-heavy:      var(--hns-global--FontWeight-black);
  --hns-global--FontWeight-regular:    var(--hns-global--FontWeight-normal);


  /**
   * Font sizes
   */

  --hns-global--FontSize-list--md: 1.500rem;  /* Medium: 24px */

  --hns-global--FontSize-title--2xs: 1rem;      /* Extra extrasmall: 16px */
  --hns-global--FontSize-title--xs:  1.375rem;  /* Extrasmall: 22px */
  --hns-global--FontSize-title--sm:  1.500rem;  /* Small: 24px */
  --hns-global--FontSize-title--md:  1.625rem;  /* Medium: 26px */
  --hns-global--FontSize-title--lg:  1.875rem;  /* Large: 30px */
  --hns-global--FontSize-title--xl:  2.250rem;  /* Extralarge: 35px */
  --hns-global--FontSize-title--2xl: 2.812rem;  /* Extra extralarge: 45px */
  --hns-global--FontSize-title--3xl: 3.750rem;  /* Extra extra extralarge: 60px */
  --hns-global--FontSize-title--4xl: 4.375rem;  /* Extra extra extra extralarge: 70px */

  --hns-global--FontSize-subtitle--xs:  1.250rem;  /* Extrasmall: 20px */
  --hns-global--FontSize-subtitle--sm:  1.375rem;  /* Small: 22px */
  --hns-global--FontSize-subtitle--md:  2.187rem;  /* Medium: 35px */

  --hns-global--FontSize-paragraph--2xs:  .875rem;  /* Extra extrasmall: 14px */
  --hns-global--FontSize-paragraph--xs:   .937rem;  /* Extrasmall: 15px */
  --hns-global--FontSize-paragraph--sm:  1.062rem;  /* Small: 17px */
  --hns-global--FontSize-paragraph--md:  1.250rem;  /* Medium: 20px */

  --hns-global--FontSize-label--2xs:  .750rem;  /* Extra extrasmall: 12px */
  --hns-global--FontSize-label--xs:   .875rem;  /* Extrasmall: 14px */
  --hns-global--FontSize-label--sm:  1rem;      /* Small: 16px */
  --hns-global--FontSize-label--md:  1.125rem;  /* Medium: 18px */
  --hns-global--FontSize-label--lg:  1.250rem;  /* Large: 20px */

  --hns-global--FontSize-button--xs:    1rem;      /* Extrasmall: 16px */
  --hns-global--FontSize-button--sm:    1.125rem;  /* Small: 18px */
  --hns-global--FontSize-button--lg:    1.750rem;  /* Large: 28px */
}
