/** ***********************************************************************************
 * Layout design tokens
 *********************************************************************************** */

:root {
  /**
   * Breakpoints
   */

  --hns-global--breakpoints--sm:  23rem;  /** Extrasmall: 375px */
  --hns-global--breakpoints--sm:  34rem;  /** Small: 544px */
  --hns-global--breakpoints--md:  48rem;  /** Medium: 768px */
  --hns-global--breakpoints--lg:  64rem;  /** Large: 1024px */
  --hns-global--breakpoints--xl:  80rem;  /** Extralarge: 1280px */
  --hns-global--breakpoints--2xl: 90rem;  /** Extra extralarge: 1440px */


  /**
   * Layout
   */

  --hns-global--content-grid--tn--columncount: 12;
  --hns-global--content-grid--sm--columncount: 14;
  --hns-global--content-grid--columncount: var(--hns-global--content-grid--tn--columncount);

  --hns-global--content-grid--tn--column-width: 7.1875vw;  /* 100 / (23 * 12 + 4 * 11 ) * 23 */
  --hns-global--content-grid--sm--column-width: 4.4118vw;  /* 100 / (3 * 14 + 2 * 13 ) * 3 */
  --hns-global--content-grid--xl--column-width: 4rem;  /* 64px */
  --hns-global--content-grid--column-width: var(--hns-global--content-grid--tn--column-width);

  --hns-global--content-grid--tn--gutter-width: 1.25vw;  /* 100 / (23 * 12 + 4 * 11 ) * 4 */
  --hns-global--content-grid--sm--gutter-width: 2.9411vw;  /* 100 / (3 * 14 + 2 * 13 ) * 2 */
  --hns-global--content-grid--xl--gutter-width: 2.625rem; /* 42px */
  --hns-global--content-grid--gutter-width: var(--hns-global--content-grid--tn--gutter-width);

  --hns-global--content-grid--content-width: calc(var(--hns-global--content-grid--columncount) * var(--hns-global--content-grid--column-width) + (var(--hns-global--content-grid--columncount) - 1) * var(--hns-global--content-grid--gutter-width));

  --hns-global--content-grid--TemplateColumns:
    repeat(
      calc(var(--hns-global--content-grid--columncount) - 1),
      var(--hns-global--content-grid--column-width) var(--hns-global--content-grid--gutter-width)
    )
    var(--hns-global--content-grid--column-width);

  --hns-global--page-grid--offset-width: 1fr;

  --hns-global--page-grid--TemplateColumns:
    [viewport-start]
    var(--hns-global--page-grid--offset-width)
    [content-start]
    var(--hns-global--content-grid--TemplateColumns)
    [content-end]
    var(--hns-global--page-grid--offset-width)
    [viewport-end];
}

/**
 * Small
 */

@media all and (min-width: 34rem) {
  :root {
    --hns-global--content-grid--column-width: var(--hns-global--content-grid--sm--column-width);
    --hns-global--content-grid--columncount: var(--hns-global--content-grid--sm--columncount);
    --hns-global--content-grid--gutter-width: var(--hns-global--content-grid--sm--gutter-width);
  }
}

/**
 * Huge
 */

@media all and (min-width: 90rem) {
  :root {
    --hns-global--content-grid--column-width: var(--hns-global--content-grid--xl--column-width);
    --hns-global--content-grid--gutter-width: var(--hns-global--content-grid--xl--gutter-width);
  }
}



/**
 * Old code
 */

:root {
  --column-count: var(--hns-global--content-grid--columncount);
  --width-column: var(--hns-global--content-grid--column-width);
  --width-gutter: var(--hns-global--content-grid--gutter-width);
  --width-offset: var(--hns-global--page-grid--offset-width);
  --columns-default: var(--hns-global--page-grid--TemplateColumns);

  --column-content-start: 4;
  --column-content-end: -6;
}

@media all and (min-width: 34rem) { /* sm */
  :root {
    --column-content-start: 5;
  }
}

@media all and (min-width: 90rem) { /* hg */
  :root {
    --column-content-start: 7;
    --column-content-end: -7;
  }
}

/* misc variables */

:root {
  --ratio-height-rock: calc(92 / 114);
}
