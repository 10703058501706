/** ***************************************************************************
 * Form styles
 *************************************************************************** */

:root {
  /**
   * Form widget
   */

  --hns-c-page__form-widget--AccentColor: var(--hns-c-page--AccentColor);
  --hns-c-page__form-widget--Border: 1px solid var(--hns-global--palette-blue--60);
  --hns-c-page__form-widget--BorderRadius: .214em;  /* 3px */


  /**
   * Wide form widget
   */

  --hns-c-page__form-widget-wide--BackgroundColor: var(--hns-global--palette-white);
  --hns-c-page__form-widget-wide--Font: var(--hns-global--FontWeight-regular) var(--hns-global--FontSize-paragraph--2xs) var(--hns-global--FontFamily-normal);
  --hns-c-page__form-widget-wide--PaddingBlock: .5em;  /* 7px */
  --hns-c-page__form-widget-wide--PaddingInline: .857em;  /* 12px */
  --hns-c-page__form-widget-wide--TransitionDuration: var(--hns-global--Duration--xs);

  --hns-c-page__form-widget-wide--focus--BorderColor: 1px solid var(--hns-global--palette-blue--100);


  /**
   * Select
   */

  --gw-c-page__form-widget-select--icon-inlineSize: 1em;

  --gw-c-page__form-widget-select--BackgroundImage: url("data:image/svg+xml,%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%20512%20296%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%2306315e%22%20d%3D%22M494.494%2C71.479l-210.716%2C210.555c-15.341%2C15.33%20-40.215%2C15.33%20-55.556%2C0l-210.716%2C-210.555c-15.341%2C-15.33%20-15.341%2C-40.184%20-0%2C-55.513c7.367%2C-7.362%2017.359%2C-11.497%2027.778%2C-11.497l421.432%2C-0c21.696%2C-0%2039.284%2C17.574%2039.284%2C39.253c-0%2C10.411%20-4.139%2C20.395%20-11.506%2C27.757Z%22%2F%3E%3C%2Fsvg%3E");
  --gw-c-page__form-widget-select--BackgroundSize: var(--gw-c-page__form-widget-select--icon-inlineSize) auto;


  /**
   * Narrow form widget
   */

  --hns-c-page__form-widget-narrow--Width: .857em;  /* 12px */
  --hns-c-page__form-widget-narrow--Padding: .143em;  /* 2px */
}



/**
 * Form widget
 * 1: reset
 */

input,
select,
textarea {
  accent-color: var(--hns-c-page__form-widget--AccentColor);
  border-radius: var(--hns-c-page__form-widget--BorderRadius);
  border: var(--hns-c-page__form-widget--Border);
  margin: 0;  /* 1 */
}



/**
 * Image input
 */

input[type="image"] {
  border: 0;
}



/**
 * Wide form widgets
 * 1: reset
 */

input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"],
input[type="password"],
select,
textarea {
  appearance: none;
  background-color: var(--hns-c-page__form-widget-wide--BackgroundColor);
  color: currentcolor;
  font: var(--hns-c-page__form-widget-wide--Font);
  padding-block: var(--hns-c-page__form-widget-wide--PaddingBlock);
  padding-inline: var(--hns-c-page__form-widget-wide--PaddingInline);
  transition: border-color var(--hns-c-page__form-widget-wide--TransitionDuration) linear;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus {
  border-color: var(--hns-c-page__form-widget-wide--focus--BorderColor);
  outline: inherit;  /* 1 */
}


@supports not (padding-block: var(--hns-c-page__form-widget-wide--PaddingBlock)) {
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="number"],
  input[type="password"],
  textarea {
    padding: var(--hns-c-page__form-widget-wide--PaddingBlock) var(--hns-c-page__form-widget-wide--PaddingInline);
  }
}



/**
 * Select
 */

select {
  background-color: transparent;
  background-image: var(--gw-c-page__form-widget-select--BackgroundImage);
  background-position: calc(100% - var(--hns-c-page__form-widget-wide--PaddingInline)) center;
  background-repeat: no-repeat;
  background-size: var(--gw-c-page__form-widget-select--BackgroundSize);
  color: var(--gw-c-page__form-widget-select--Color);
  cursor: pointer;
  padding-inline-end: calc(2 * var(--gw-c-page__form-widget-wide--PaddingInline) + var(--gw-c-page__form-widget-select--icon-inlineSize));
}



/**
 * Narrow fields
 * 1: reset
 * 2: make it square
 */

input[type="checkbox"],
input[type="radio"] {
  appearance: none;  /* 1 */
  cursor: pointer;
  height: var(--hns-c-page__form-widget-narrow--Width);  /* 2 */
  padding: var(--hns-c-page__form-widget-narrow--Padding);
  width: var(--hns-c-page__form-widget-narrow--Width);  /* 2 */
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: var(--hns-c-page__form-widget--AccentColor);
}



/**
 * Checked tick
 */

input[type="checkbox"]::after,
input[type="radio"]::after {
  background-color: currentcolor;
  content: "";
  display: block;
  height: 100%;
  transform: scale(0);
  transition: transform 100ms linear;
  width: 100%;
}

input[type="checkbox"]:checked::after,
input[type="radio"]:checked::after {
  transform: scale(1);
}



/**
 * Radio button
 */

input[type="radio"],
input[type="radio"]::after {
  border-radius: 50%;
}
