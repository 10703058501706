/** ***********************************************************************************
 * Common styles
 *********************************************************************************** */

:root {
  --hns-c-page--FontFamily: var(--hns-global--FontFamily-normal);
  --hns-c-page--FontWeight: var(--hns-global--FontWeight-normal);
  --hns-c-page--FontSize: 1rem;  /* 16px */


  /**
   * Default color palette
   */

  --hns-c-page--BackgroundColor-r: var(--hns-global--palette-white--100-r);
  --hns-c-page--BackgroundColor-g: var(--hns-global--palette-white--100-g);
  --hns-c-page--BackgroundColor-b: var(--hns-global--palette-white--100-b);
  --hns-c-page--BackgroundColor-a: 1;
  --hns-c-page--BackgroundColor: rgba(
    var(--hns-c-page--BackgroundColor-r),
    var(--hns-c-page--BackgroundColor-g),
    var(--hns-c-page--BackgroundColor-b),
    var(--hns-c-page--BackgroundColor-a)
  );

  --hns-c-page--Color-r: var(--hns-global--palette-blue--100-r);
  --hns-c-page--Color-g: var(--hns-global--palette-blue--100-g);
  --hns-c-page--Color-b: var(--hns-global--palette-blue--100-b);
  --hns-c-page--Color-a: 1;
  --hns-c-page--Color: rgba(
    var(--hns-c-page--Color-r),
    var(--hns-c-page--Color-g),
    var(--hns-c-page--Color-b),
    var(--hns-c-page--Color-a)
  );

  --hns-c-page--AccentColor-r: var(--hns-global--palette-yellow--100-r);
  --hns-c-page--AccentColor-g: var(--hns-global--palette-yellow--100-g);
  --hns-c-page--AccentColor-b: var(--hns-global--palette-yellow--100-b);
  --hns-c-page--AccentColor-a: 1;
  --hns-c-page--AccentColor: rgba(
    var(--hns-c-page--AccentColor-r),
    var(--hns-c-page--AccentColor-g),
    var(--hns-c-page--AccentColor-b),
    var(--hns-c-page--AccentColor-a)
  );


  /**
   * Main content
   */

  --hns-c-page__main--FontSize: var(--hns-global--FontSize-paragraph--md);


  /**
   * Obsolete
   */

  --color-background-r: var(--hns-c-page--BackgroundColor-r);
  --color-background-g: var(--hns-c-page--BackgroundColor-g);
  --color-background-b: var(--hns-c-page--BackgroundColor-b);
  --color-background-a: var(--hns-c-page--BackgroundColor-a);
  --color-background: var(--hns-c-page--BackgroundColor);

  --color-foreground-r: var(--hns-c-page--Color-r);
  --color-foreground-g: var(--hns-c-page--Color-g);
  --color-foreground-b: var(--hns-c-page--Color-b);
  --color-foreground-a: var(--hns-c-page--Color-a);
  --color-foreground: var(--hns-c-page--Color);

  --color-special-r: var(--hns-c-page--AccentColor-r);
  --color-special-g: var(--hns-c-page--AccentColor-g);
  --color-special-b: var(--hns-c-page--AccentColor-b);
  --color-special-a: var(--hns-c-page--AccentColor-a);
  --color-special: var(--hns-c-page--AccentColor);
}



/**
 * Page
 * 1: cut rocks
 * 2: reset
 */

body {
  accent-color: var(--hns-c-page--AccentColor);
  background-color: var(--hns-c-page--BackgroundColor);
  color: var(--hns-c-page--Color);
  font-family: var(--hns-c-page--FontFamily);
  font-size: var(--hns-c-page--FontSize);
  font-weight: var(--hns-c-page--FontWeight);
  margin: 0;  /* 2 */
  max-width: 100vw;
  overflow-x: hidden;  /* 1 */
  scrollbar-gutter: stable;
}



/**
 * Main content
 */

main {
  font-size: var(--hns-c-page__main--FontSize);
}



/**
 * Divider
 */

hr {
  background-color: currentcolor;
  border-color: currentcolor;
  border: 0;
  color: currentcolor;
  height: 1px;
}



/**
 * Image
 */

img,
svg {
  height: auto;
  max-width: 100%;
}



/**
 * Table
 * 1: reset
 */

th {
  font-weight: initial;  /* 1 */
  text-align: inherit;  /* 1 */
}



/**
 * Field labels
 */

label {
  cursor: pointer;
}
