/** ***********************************************************************************
 * Color design tokens
 *********************************************************************************** */

:root {
  /**
   * Color palette
   */

  /** Blue: #0d4580 */
  --hns-global--palette-blue--100-r: 13;
  --hns-global--palette-blue--100-g: 69;
  --hns-global--palette-blue--100-b: 128;
  --hns-global--palette-blue--100: rgba(var(--hns-global--palette-blue--100-r), var(--hns-global--palette-blue--100-g), var(--hns-global--palette-blue--100-b), 1);
  --hns-global--palette-blue: var(--hns-global--palette-blue--100);

  /** Blue/10% on white */
  --hns-global--palette-blue--10-a: .1;
  --hns-global--palette-blue--10-r: calc(var(--hns-global--palette-blue--100-r) + (255 - var(--hns-global--palette-blue--100-r)) * (1 - var(--hns-global--palette-blue--10-a)));
  --hns-global--palette-blue--10-g: calc(var(--hns-global--palette-blue--100-g) + (255 - var(--hns-global--palette-blue--100-g)) * (1 - var(--hns-global--palette-blue--10-a)));
  --hns-global--palette-blue--10-b: calc(var(--hns-global--palette-blue--100-b) + (255 - var(--hns-global--palette-blue--100-b)) * (1 - var(--hns-global--palette-blue--10-a)));
  --hns-global--palette-blue--10: rgba(var(--hns-global--palette-blue--10-r), var(--hns-global--palette-blue--10-g), var(--hns-global--palette-blue--10-b), 1);

  /** Blue/20% on white (unused) */
  --hns-global--palette-blue--20-a: .2;
  --hns-global--palette-blue--20-r: calc(var(--hns-global--palette-blue--100-r) + (255 - var(--hns-global--palette-blue--100-r)) * (1 - var(--hns-global--palette-blue--20-a)));
  --hns-global--palette-blue--20-g: calc(var(--hns-global--palette-blue--100-g) + (255 - var(--hns-global--palette-blue--100-g)) * (1 - var(--hns-global--palette-blue--20-a)));
  --hns-global--palette-blue--20-b: calc(var(--hns-global--palette-blue--100-b) + (255 - var(--hns-global--palette-blue--100-b)) * (1 - var(--hns-global--palette-blue--20-a)));
  --hns-global--palette-blue--20: rgba(var(--hns-global--palette-blue--20-r), var(--hns-global--palette-blue--20-g), var(--hns-global--palette-blue--20-b), 1);

  /** Blue/30% on white */
  --hns-global--palette-blue--30-a: .3;
  --hns-global--palette-blue--30-r: calc(var(--hns-global--palette-blue--100-r) + (255 - var(--hns-global--palette-blue--100-r)) * (1 - var(--hns-global--palette-blue--30-a)));
  --hns-global--palette-blue--30-g: calc(var(--hns-global--palette-blue--100-g) + (255 - var(--hns-global--palette-blue--100-g)) * (1 - var(--hns-global--palette-blue--30-a)));
  --hns-global--palette-blue--30-b: calc(var(--hns-global--palette-blue--100-b) + (255 - var(--hns-global--palette-blue--100-b)) * (1 - var(--hns-global--palette-blue--30-a)));
  --hns-global--palette-blue--30: rgba(var(--hns-global--palette-blue--30-r), var(--hns-global--palette-blue--30-g), var(--hns-global--palette-blue--30-b), 1);

  /** Blue/40% on white */
  --hns-global--palette-blue--40-a: .4;
  --hns-global--palette-blue--40-r: calc(var(--hns-global--palette-blue--100-r) + (255 - var(--hns-global--palette-blue--100-r)) * (1 - var(--hns-global--palette-blue--40-a)));
  --hns-global--palette-blue--40-g: calc(var(--hns-global--palette-blue--100-g) + (255 - var(--hns-global--palette-blue--100-g)) * (1 - var(--hns-global--palette-blue--40-a)));
  --hns-global--palette-blue--40-b: calc(var(--hns-global--palette-blue--100-b) + (255 - var(--hns-global--palette-blue--100-b)) * (1 - var(--hns-global--palette-blue--40-a)));
  --hns-global--palette-blue--40: rgba(var(--hns-global--palette-blue--40-r), var(--hns-global--palette-blue--40-g), var(--hns-global--palette-blue--40-b), 1);

  /** Blue/50% on white (unused) */
  --hns-global--palette-blue--50-a: .5;
  --hns-global--palette-blue--50-r: calc(var(--hns-global--palette-blue--100-r) + (255 - var(--hns-global--palette-blue--100-r)) * (1 - var(--hns-global--palette-blue--50-a)));
  --hns-global--palette-blue--50-g: calc(var(--hns-global--palette-blue--100-g) + (255 - var(--hns-global--palette-blue--100-g)) * (1 - var(--hns-global--palette-blue--50-a)));
  --hns-global--palette-blue--50-b: calc(var(--hns-global--palette-blue--100-b) + (255 - var(--hns-global--palette-blue--100-b)) * (1 - var(--hns-global--palette-blue--50-a)));
  --hns-global--palette-blue--50: rgba(var(--hns-global--palette-blue--50-r), var(--hns-global--palette-blue--50-g), var(--hns-global--palette-blue--50-b), 1);

  /** Blue/60% on white */
  --hns-global--palette-blue--60-a: .6;
  --hns-global--palette-blue--60-r: calc(var(--hns-global--palette-blue--100-r) + (255 - var(--hns-global--palette-blue--100-r)) * (1 - var(--hns-global--palette-blue--60-a)));
  --hns-global--palette-blue--60-g: calc(var(--hns-global--palette-blue--100-g) + (255 - var(--hns-global--palette-blue--100-g)) * (1 - var(--hns-global--palette-blue--60-a)));
  --hns-global--palette-blue--60-b: calc(var(--hns-global--palette-blue--100-b) + (255 - var(--hns-global--palette-blue--100-b)) * (1 - var(--hns-global--palette-blue--60-a)));
  --hns-global--palette-blue--60: rgba(var(--hns-global--palette-blue--60-r), var(--hns-global--palette-blue--60-g), var(--hns-global--palette-blue--60-b), 1);

  /** Blue/80% on white */
  --hns-global--palette-blue--80-a: .8;
  --hns-global--palette-blue--80-r: calc(var(--hns-global--palette-blue--100-r) + (255 - var(--hns-global--palette-blue--100-r)) * (1 - var(--hns-global--palette-blue--80-a)));
  --hns-global--palette-blue--80-g: calc(var(--hns-global--palette-blue--100-g) + (255 - var(--hns-global--palette-blue--100-g)) * (1 - var(--hns-global--palette-blue--80-a)));
  --hns-global--palette-blue--80-b: calc(var(--hns-global--palette-blue--100-b) + (255 - var(--hns-global--palette-blue--100-b)) * (1 - var(--hns-global--palette-blue--80-a)));
  --hns-global--palette-blue--80: rgba(var(--hns-global--palette-blue--80-r), var(--hns-global--palette-blue--80-g), var(--hns-global--palette-blue--80-b), 1);


  /** Dark: #06315e */
  --hns-global--palette-dark--100-r: 6;
  --hns-global--palette-dark--100-g: 49;
  --hns-global--palette-dark--100-b: 94;
  --hns-global--palette-dark--100: rgba(var(--hns-global--palette-dark--100-r), var(--hns-global--palette-dark--100-g), var(--hns-global--palette-dark--100-b), 1);
  --hns-global--palette-dark: var(--hns-global--palette-dark--100);


  /** White: #fff */
  --hns-global--palette-white--100-r: 255;
  --hns-global--palette-white--100-g: 255;
  --hns-global--palette-white--100-b: 255;
  --hns-global--palette-white--100: rgba(var(--hns-global--palette-white--100-r), var(--hns-global--palette-white--100-g), var(--hns-global--palette-white--100-b), 1);
  --hns-global--palette-white: var(--hns-global--palette-white--100);


  /** Gray: #4c5166 */
  --hns-global--palette-gray--100-r: 76;
  --hns-global--palette-gray--100-g: 81;
  --hns-global--palette-gray--100-b: 102;
  --hns-global--palette-gray--100: rgba(var(--hns-global--palette-gray--100-r), var(--hns-global--palette-gray--100-g), var(--hns-global--palette-gray--100-b), 1);
  --hns-global--palette-gray: var(--hns-global--palette-gray--100);


  /** Black: #000 */
  --hns-global--palette-black--100-r: 0;
  --hns-global--palette-black--100-g: 0;
  --hns-global--palette-black--100-b: 0;
  --hns-global--palette-black--100: rgba(var(--hns-global--palette-black--100-r), var(--hns-global--palette-black--100-g), var(--hns-global--palette-black--100-b), 1);
  --hns-global--palette-black: var(--hns-global--palette-black--100);


  /** Brand: #c69a04 */
  --hns-global--palette-brand--100-r: 198;
  --hns-global--palette-brand--100-g: 154;
  --hns-global--palette-brand--100-b: 4;
  --hns-global--palette-brand--100: rgba(var(--hns-global--palette-brand--100-r), var(--hns-global--palette-brand--100-g), var(--hns-global--palette-brand--100-b), 1);
  --hns-global--palette-brand: var(--hns-global--palette-brand--100);

  /** Red: #e60000 */
  --hns-global--palette-red--100-r: 230;
  --hns-global--palette-red--100-g: 0;
  --hns-global--palette-red--100-b: 0;
  --hns-global--palette-red--100: rgba(var(--hns-global--palette-red--100-r), var(--hns-global--palette-red--100-g), var(--hns-global--palette-red--100-b), 1);
  --hns-global--palette-red: var(--hns-global--palette-red--100);

  /** Yellow: #e5b511 */
  --hns-global--palette-yellow--100-r: 229;
  --hns-global--palette-yellow--100-g: 181;
  --hns-global--palette-yellow--100-b: 17;
  --hns-global--palette-yellow--100: rgba(var(--hns-global--palette-yellow--100-r), var(--hns-global--palette-yellow--100-g), var(--hns-global--palette-yellow--100-b), 1);
  --hns-global--palette-yellow: var(--hns-global--palette-yellow--100);

  /** Yellow/20% on white */
  --hns-global--palette-yellow--20-a: .2;
  --hns-global--palette-yellow--20-r: calc(var(--hns-global--palette-yellow--100-r) + (255 - var(--hns-global--palette-yellow--100-r)) * (1 - var(--hns-global--palette-yellow--20-a)));
  --hns-global--palette-yellow--20-g: calc(var(--hns-global--palette-yellow--100-g) + (255 - var(--hns-global--palette-yellow--100-g)) * (1 - var(--hns-global--palette-yellow--20-a)));
  --hns-global--palette-yellow--20-b: calc(var(--hns-global--palette-yellow--100-b) + (255 - var(--hns-global--palette-yellow--100-b)) * (1 - var(--hns-global--palette-yellow--20-a)));
  --hns-global--palette-yellow--20:  rgba(var(--hns-global--palette-yellow--20-r), var(--hns-global--palette-yellow--20-g), var(--hns-global--palette-yellow--20-b), 1);

  /** Yellow/40% on white */
  --hns-global--palette-yellow--40-a: .4;
  --hns-global--palette-yellow--40-r: calc(var(--hns-global--palette-yellow--100-r) + (255 - var(--hns-global--palette-yellow--100-r)) * (1 - var(--hns-global--palette-yellow--40-a)));
  --hns-global--palette-yellow--40-g: calc(var(--hns-global--palette-yellow--100-g) + (255 - var(--hns-global--palette-yellow--100-g)) * (1 - var(--hns-global--palette-yellow--40-a)));
  --hns-global--palette-yellow--40-b: calc(var(--hns-global--palette-yellow--100-b) + (255 - var(--hns-global--palette-yellow--100-b)) * (1 - var(--hns-global--palette-yellow--40-a)));
  --hns-global--palette-yellow--40:  rgba(var(--hns-global--palette-yellow--40-r), var(--hns-global--palette-yellow--40-g), var(--hns-global--palette-yellow--40-b), 1);

  /** Yellow/50% on white */
  --hns-global--palette-yellow--50-a: .5;
  --hns-global--palette-yellow--50-r: calc(var(--hns-global--palette-yellow--100-r) + (255 - var(--hns-global--palette-yellow--100-r)) * (1 - var(--hns-global--palette-yellow--50-a)));
  --hns-global--palette-yellow--50-g: calc(var(--hns-global--palette-yellow--100-g) + (255 - var(--hns-global--palette-yellow--100-g)) * (1 - var(--hns-global--palette-yellow--50-a)));
  --hns-global--palette-yellow--50-b: calc(var(--hns-global--palette-yellow--100-b) + (255 - var(--hns-global--palette-yellow--100-b)) * (1 - var(--hns-global--palette-yellow--50-a)));
  --hns-global--palette-yellow--50:  rgba(var(--hns-global--palette-yellow--50-r), var(--hns-global--palette-yellow--50-g), var(--hns-global--palette-yellow--50-b), 1);

  /** Yellow/60% on white */
  --hns-global--palette-yellow--60-a: .6;
  --hns-global--palette-yellow--60-r: calc(var(--hns-global--palette-yellow--100-r) + (255 - var(--hns-global--palette-yellow--100-r)) * (1 - var(--hns-global--palette-yellow--60-a)));
  --hns-global--palette-yellow--60-g: calc(var(--hns-global--palette-yellow--100-g) + (255 - var(--hns-global--palette-yellow--100-g)) * (1 - var(--hns-global--palette-yellow--60-a)));
  --hns-global--palette-yellow--60-b: calc(var(--hns-global--palette-yellow--100-b) + (255 - var(--hns-global--palette-yellow--100-b)) * (1 - var(--hns-global--palette-yellow--60-a)));
  --hns-global--palette-yellow--60:  rgba(var(--hns-global--palette-yellow--60-r), var(--hns-global--palette-yellow--60-g), var(--hns-global--palette-yellow--60-b), 1);

  /** Yellow/80% on white */
  --hns-global--palette-yellow--80-a: .8;
  --hns-global--palette-yellow--80-r: calc(var(--hns-global--palette-yellow--100-r) + (255 - var(--hns-global--palette-yellow--100-r)) * (1 - var(--hns-global--palette-yellow--80-a)));
  --hns-global--palette-yellow--80-g: calc(var(--hns-global--palette-yellow--100-g) + (255 - var(--hns-global--palette-yellow--100-g)) * (1 - var(--hns-global--palette-yellow--80-a)));
  --hns-global--palette-yellow--80-b: calc(var(--hns-global--palette-yellow--100-b) + (255 - var(--hns-global--palette-yellow--100-b)) * (1 - var(--hns-global--palette-yellow--80-a)));
  --hns-global--palette-yellow--80:  rgba(var(--hns-global--palette-yellow--80-r), var(--hns-global--palette-yellow--80-g), var(--hns-global--palette-yellow--80-b), 1);
}
